export default class Helpers {
    static findCarImageModule(team, name) {
        const formattedTeamName = this.formatTextForAssetNames(team);
        try {
            return require(`../assets/cars/${formattedTeamName}-car.webp`);
        } catch (e) {
            if (name === "Formula One") return require(`../brand/vc-za-en/assets/icons/car-icon.png`);
            if (name === "MotoGP") return require(`../brand/vc-za-en/assets/icons/bike-icon.webp`);
        }
    }

    static findTeamImageModule(teamName) {
        const reformattedTeamName = this.formatTextForAssetNames(teamName);
        try {
            return require(`../assets/teamBadges/${reformattedTeamName}-logo-icon.png`)
        } catch (e) {
            return require(`../assets/default-badge.webp`)
        }
    }

    static formatTextForAssetNames(text) {
        const lowerCase = text.toLowerCase();
        return lowerCase.replaceAll(' ', '-');
    }
}
