<template>
  <div class="vl-parent">
    <loading v-model:active="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"/>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'LoadingOverlay',
  components: {
    Loading
  },
  data() {
    return {
      isLoading: true,
      fullPage: true
    }
  },
}
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  height: 100%;
  background-color: rgba(59, 58, 58, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  overflow: hidden;
}

.spinner {
  display: inline-block;
}
</style>
