export function  injectScript() {
    return new Promise((resolve, reject) => {
        window.utag_cfg_ovrd = window.utag_cfg_ovrd || {}
        window.utag_cfg_ovrd.noview = true

        const scriptUrl = 'https://tags.tiqcdn.com/utag/vodafone/za-motorsport-zone/prod/utag.js';
        const document = window.document;
        const scriptElement = document.createElement('script');

        scriptElement.src = scriptUrl;
        scriptElement.type = 'text/javascript';  // Corrected type
        scriptElement.async = true;

        scriptElement.onload = () => resolve();
        scriptElement.onerror = () => reject(new Error('Script load error'));

        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(scriptElement, firstScript);
    });
}
