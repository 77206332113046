export const brandConfigObjectMap = new Map();

export const Brands = {
    VodacomSouthAfricaEnglish: 'vc-za-en',
    VodacomEvinaSouthAfricaEnglish: 'vc-encrypt-za-en',
};

brandConfigObjectMap.set(Brands.VodacomSouthAfricaEnglish, {
    gameTitle: 'Motorsport Zone',
    freePlayServiceId: 'motosport-free',
    baseRoute: '/home'
});

