import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class FantasyService {
    static async getNews(type) {
        const failSafeType = type ? type : 'composite'
        const response = await axios.post(`${environmentConfig.fantasyService}/news/get-news-feed-items`, {
            sportId: 4,
            type: failSafeType
        })
        return response.data.recordset
    }

    static async getRaceEvents(fixtureId) {
        const response = await axios.post(`${environmentConfig.fantasyService}/motorsport/get-prelim-races`, {
            fixtureId
        })
        return response.data.recordset
    }

    static async submitCaptain(roundId, msisdn, sportId, playerId) {
        const response = await axios.post(`${environmentConfig.fantasyService}/motorsport/update-captain`, {
            roundId,
            msisdn,
            sportId,
            playerId
        })
        return response.data
    }

    static async postDriverEntries(entries) {
        const response = await axios.post(`${environmentConfig.fantasyService}/motorsport/post-driver-entries`, {
            entries
        })
        return response.data
    }

    static async postConstructorEntries(entries) {
        const response = await axios.post(`${environmentConfig.fantasyService}/motorsport/post-constructor-entries`, {
            entries
        })
        return response.data
    }

    static async getUserEntries(roundId, msisdn) {
        const response = await axios.post(`${environmentConfig.fantasyService}/motorsport/get-user-entries`, {
            msisdn,
            roundId,
        })
        return response.data.recordset
    }

    static async getRoundPlayers(position, roundId) {
        const response = await axios.post(`${environmentConfig.fantasyService}/motorsport/get-round-players`, {
            position,
            roundId,
            pageNumber: 1,
            rowsOfPage: 10,
            sportId: 4,
        })
        return response.data
    }

    static async getUpcomingFixtures() {
        const response = await axios.post(`${environmentConfig.fantasyService}/fixtures/get-upcoming-fixtures`, {
            sportId: 4,
        })
        return response.data
    }

    static async getUserLandingStats(msisdn) {
        const response = await axios.post(`${environmentConfig.fantasyService}/profile/get-user-stats`, {
            msisdn: msisdn,
            sportId: 4,
        })
        return response.data
    }

    static async getRounds(msisdn, roundId) {
        const response = await axios.post(`${environmentConfig.fantasyService}/motorsport/get-upcoming-rounds`, {
            msisdn: msisdn,
            pageNumber: 1,
            rowsOfPage: 10,
            sportId: 4,
            roundId: roundId
        })
        return response.data
    }

    static async getActiveLeagues() {
        const response = await axios.post(`${environmentConfig.fantasyService}/motorsport/get-leagues`, {
            sportId: 4,
        })
        return response.data
    }

    static async getTopPicks(leagueAbbriviation, season) {
        const response = await axios.post(`${environmentConfig.fantasyService}/motorsport/get-top-players-in-league`, {
            ref: leagueAbbriviation,
            seasonRef: season,
            sportId: 4,
        })
        return response.data
    }

    static async getRoundLeaders(msisdn, id) {
        const response = await axios.post(`${environmentConfig.fantasyService}/motorsport/get-round-leaders`, {
            id,
            msisdn,
            sportId: 4,
            userAccessId: 10
        })
        return response.data
    }

    static async getCompletedRounds(msisdn) {
        const response = await axios.post(`${environmentConfig.fantasyService}/motorsport/get-completed-rounds`, {
            msisdn: msisdn,
            pageNumber: 1,
            rowsOfPage: 10,
            sportId: 4,
        })
        return response.data
    }
}

