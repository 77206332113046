import {createRouter, createWebHistory} from 'vue-router';
import {userAuthenticate} from "@/functions/userAuthenticate";
import {brandConfigMap} from "@/brand";
import {utagDataPost} from "@/functions/utagDataPost";

const routes = [
    {
        path: '/',
        redirect: getBaseRoute(),
    },
    // {
    //     path: '/welcome',
    //     name: 'WelcomeView',
    //     component: () => import("@/views/WelcomeView.vue"),
    // },
    {
        path: '/request-otp',
        name: 'RequestOtp',
        component: () => import("@/views/RequestOtp.vue"),
    },
    {
        path: '/submit-otp',
        name: 'SubmitOtp',
        component: () => import("@/views/SubmitOtp.vue"),
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: () => import("@/views/TermsAndConditions.vue"),
    },
    {
        path: '/subscription',
        name: 'SubscriptionView',
        component: () => import("@/views/SubscriptionView.vue"),
    },
    {
        path: '/confirm-subscription/:price/:interval',
        name: 'ConfirmSubscription',
        component: () => import("@/views/ConfirmSubscription.vue"),
    },
    {
        path: '/home',
        name: 'HomeView',
        component: () => import("@/views/HomeView.vue"),
    },
    {
        path: '/menu',
        name: 'MenuView',
        component: () => import("@/views/MenuView.vue"),
    },
    {
        path: '/my-profile',
        name: 'MyProfile',
        component: () => import("@/views/MyProfile.vue"),
    },
    {
        path: '/upcoming-fixtures/:roundId',
        name: 'UpcomingFixtures',
        component: () => import("@/views/fantasy/UpcomingFixtures.vue"),
    },
    {
        path: '/upcoming-match/:fixtureId',
        name: 'UpcomingMatch',
        component: () => import("@/views/UpcomingMatch.vue"),
    },
    {
        path: '/build-team/:roundId',
        name: 'BuildTeam',
        props: true,
        component: () => import("@/views/fantasy/BuildTeam.vue"),
    },
    {
        path: '/choose-players/:roundId/:position',
        name: 'ChoosePlayers',
        props: true,
        component: () => import("@/views/fantasy/ChoosePlayers.vue"),
    },
    {
        path: '/news',
        name: 'NewsView',
        component: () => import("@/views/NewsView.vue"),
        children: [
            {
                path: 'home',
                name: 'NewsHome',
                component: () => import("@/views/news/NewsHome.vue"),
            },
        ]
    },
    {
        path: '/fantasy',
        name: 'FantasyView',
        component: () => import("@/views/FantasyView.vue"),
        children: [
            {
                path: 'home',
                name: 'FantasyHome',
                component: () => import("@/views/fantasy/FantasyHome.vue"),
            },
            {
                path: 'top-picks',
                name: 'TopPicksView',
                component: () => import("@/views/fantasy/TopPicksView.vue"),
            },
            {
                path: 'upcoming-rounds',
                name: 'FantasyRounds',
                component: () => import("@/views/fantasy/FantasyRounds.vue"),
            },
            {
                path: 'my-rounds',
                name: 'MyRounds',
                component: () => import("@/views/fantasy/MyRounds.vue"),
            },
            {
                path: 'leaderboard',
                name: 'LeaderboardView',
                component: () => import("@/views/fantasy/LeaderboardView.vue"),
            },
        ]
    },
    {
        path: '/predictor',
        name: 'PredictorView',
        component: () => import("@/views/PredictorView.vue"),
        children: [
            {
                path: 'home',
                name: 'PredictorHome',
                component: () => import("@/views/predictor/PredictorHome.vue"),
            },
            {
                path: 'results',
                name: 'PredictorResults',
                component: () => import("@/views/predictor/PredictorResults.vue"),
            },
            {
                path: 'upcoming-rounds',
                name: 'PredictorUpcomingRounds',
                component: () => import("@/views/predictor/PredictorUpcomingRounds.vue"),
            },
            {
                path: 'make-predictions/:position/:roundId/:raceId',
                name: 'PredictorMakePredictions',
                component: () => import("@/views/predictor/DriversConstructors.vue"),
            },
            {
                path: 'my-rounds',
                name: 'PredictorMyRounds',
                component: () => import("@/views/predictor/PredictorMyRounds.vue"),
            },
            {
                path: 'leaderboard',
                name: 'PredictorLeaderboard',
                component: () => import("@/views/predictor/PredictorLeaderboard.vue"),
            },
            {
                path: 'round-leaderboard',
                name: 'PredictorRoundLeaderboard',
                component: () => import("@/views/predictor/PredictorRoundLeaderboard.vue"),
            },
        ]
    },
    {
        path: '/trivia',
        name: 'TriviaView',
        component: () => import("@/views/TriviaView.vue"),
        children: [
            {
                path: 'home',
                name: 'TriviaHome',
                component: () => import("@/views/trivia/TriviaHome.vue"),
            },
            {
                path: 'progress',
                name: 'TriviaProgress',
                component: () => import("@/views/trivia/TriviaProgress.vue"),
            },
            {
                path: 'questions',
                name: 'TriviaQuestions',
                component: () => import("@/views/trivia/TriviaQuestions.vue"),
            },
            {
                path: 'how-to-play',
                name: 'HowToPlay',
                component: () => import("@/views/trivia/HowToPlay.vue"),
            },
            {
                path: 'prizes',
                name: 'PrizesView',
                component: () => import("@/views/trivia/PrizesView.vue"),
            },
        ]
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0);
    }
})

function getBaseRoute() {
    const domain = window.origin;
    const brandConfig = brandConfigMap.get(domain) ? brandConfigMap.get(domain) : brandConfigMap.get('default');
    return brandConfig.baseRoute;
}

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next);
})

router.afterEach((to, from, next) => utagDataPost(to, from, next));

export default router
