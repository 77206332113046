export const styles = {
    backgrounds: [
        { className: "primary-bg-colour", colour: "#A00000" },
        { className: "secondary-bg-colour", colour: "#181818" },
        { className: "tertiary-bg-colour", colour: "#0D0D0D" },
        { className: "inactive-bg-colour", colour: "#141414" },
        { className: "misc-bg-colour", colour: "#585858" },
    ],
    text: [
        { className: "primary-text", colour: "#ffffff" },
        { className: "secondary-text", colour: "#191919" },
        { className: "tertiary-text", colour: "#ffffff" },
        { className: "inactive-text", colour: "#666666" },
        { className: "misc-text", colour: "#C50000" },
    ],
    borders: [
        { className: "primary-border-colour", colour: "#C50000" },
        { className: "secondary-border-colour", colour: "#191919" },
        { className: "primary-free-border-colour", colour: "#44444440" },
    ],
};

